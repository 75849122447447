"use client"

import { Button } from "@/components/ui/button"
import { ArrowRight, BarChart3, Check, ChevronDown, ChevronUp, Lightbulb, MessageCircle, PhoneCall } from "lucide-react"
import { useEffect, useState } from "react"
import mixpanel from "mixpanel-browser"
import { Link } from "react-router-dom"

export function LandingPage() {
  const [openFaq, setOpenFaq] = useState<number | null>(null)

  const toggleFaq = (index: number) => {
    setOpenFaq(openFaq === index ? null : index)
  }

  const faqs = [
    {
      question: "¿El feedback recolectado por BoothBits es público?",
      answer:
        "No, es solo para ti. Toda la información es privada, brindándote un acceso exclusivo a los datos, pudiendo incluir feedback por parte de tu staff para que tengas controlado tanto asistentes como equipo.",
    },
    {
      question: "¿Puedo usar Boothbits en eventos de cualquier tamaño?",
      answer:
        "Boothbits es escalable y puede adaptarse a eventos de cualquier tamaño, desde pequeños conciertos hasta grandes festivales o conferencias internacionales. Ofrecemos planes flexibles que se ajustan a la magnitud de tu evento.",
    },
    {
      question: "¿Qué tipos de informes puedo generar con Boothbits?",
      answer:
        "Boothbits genera informes detallados por categoría (fortalezas, problemas, peticiones, soluciones) y por departamento (música, personal, seguridad, etc.). Puedes acceder a resúmenes visuales, gráficos interactivos y recomendaciones accionables, todo desde un panel centralizado.",
    },
    {
      question: "¿Puedo personalizar las encuestas de feedback?",
      answer:
        "Sí, puedes personalizar completamente las encuestas para adaptarlas a las necesidades específicas de tu evento. Boothbits te permite ajustar las preguntas y el formato para obtener el feedback más relevante para ti.",
    },
    {
      question: "¿Cuánto tiempo se tarda en ver los resultados?",
      answer:
        "Los resultados se ven prácticamente en tiempo real. Desde el momento en que comienza a recopilarse feedback, puedes visualizar el análisis en nuestro panel de control. Esto te permite hacer ajustes inmediatos y planificar mejoras para futuros eventos.",
    },
    {
      question: "¿Es difícil implementar Boothbits en mis eventos?",
      answer:
        "Boothbits es fácil de implementar. Te proporcionamos todas las herramientas necesarias para que el proceso de recopilación de feedback sea fluido y no interrumpa el desarrollo del evento. Además, nuestro equipo te asistirá en todo momento para asegurar que el sistema funcione de manera óptima.",
    },
  ]

  useEffect(() => {
    mixpanel.track_pageview()
  }, [])

  const redirectToSales = () => {
    window.location.href = "https://calendar.app.google/dPWb5XdarVm7popF9"
  }

  return (
    <>
      <div className="min-h-screen bg-gray-900 text-white">
        <header className="container mx-auto px-4 py-6 flex justify-between items-center">
          <div className="text-2xl font-bold">
            <a className="flex items-center justify-center" href="/">
              <img className="h-12" src="/boothbits-logo.svg" alt="BoothBits logo" />
            </a>
          </div>
        </header>

        <main>
          <section className="container mx-auto px-4 py-20 text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-6">Gestión de feedback mejorado con IA</h1>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              Boothbits recopila y analiza el feedback de asistentes con IA especializada para eventos, resumiendo los
              datos para que los leas en menos de 10 minutos.
            </p>
            <div className="flex justify-center space-x-4">
              <Button className="bg-purple-600 hover:bg-purple-700 text-lg px-8 py-4" onClick={redirectToSales}>
                Quiero una demo
              </Button>
            </div>
          </section>

          <section id="how-it-works" className="bg-gray-800 py-20">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold mb-12 text-center">Cómo funciona</h2>
              <div className="grid md:grid-cols-4 gap-8">
                {[
                  {
                    title: "Recopila Opiniones",
                    description: "Obtén información de los asistentes a través de encuestas dinámicas",
                  },
                  { title: "Análisis con IA", description: "Nuestra IA procesa y analiza los datos recopilados" },
                  { title: "Genera Informes", description: "Recibe informes detallados de los datos de tu evento" },
                  { title: "Mejora tus Eventos", description: "Implementa recomendaciones impulsadas por IA" },
                ].map((step, index) => (
                  <div key={index} className="flex flex-col items-center text-center mx-4">
                    <div className="w-16 h-16 bg-purple-600 rounded-full flex items-center justify-center text-2xl font-bold mb-4">
                      {index + 1}
                    </div>
                    <h3 className="text-xl font-semibold mb-2 leading-tight">{step.title}</h3>
                    <p className="leading-relaxed max-w-xs">{step.description}</p>

                    {index < 3 && <ArrowRight className="w-8 h-8 text-purple-400 mt-4" />}
                    {index === 3 && <Check className="w-8 h-8 text-purple-400 mt-4" />}
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section id="features" className="py-20">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold mb-12 text-center">Características clave</h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="bg-gray-700 p-6 rounded-lg">
                  <BarChart3 className="w-12 h-12 text-purple-400 mb-4" />
                  <h3 className="text-xl font-semibold mb-2">No arrastres los errores de anteriores eventos</h3>
                  <p>
                    Gracias al análisis de datos históricos que ofrece Boothbits, puedes evitar repetir errores pasados
                    y mejorar continuamente la calidad de tus eventos.
                  </p>
                </div>
                <div className="bg-gray-700 p-6 rounded-lg">
                  <MessageCircle className="w-12 h-12 text-purple-400 mb-4" />
                  <h3 className="text-xl font-semibold mb-2">Consigue datos contrastables</h3>
                  <p>
                    Ofrece una experiencia diferenciada apoyada en decisiones basadas en datos reales. Optimiza cada
                    aspecto para superar a la competencia y atraer más público.
                  </p>
                </div>
                <div className="bg-gray-700 p-6 rounded-lg">
                  <Lightbulb className="w-12 h-12 text-purple-400 mb-4" />
                  <h3 className="text-xl font-semibold mb-2">Genera conexión continua con los asistentes</h3>
                  <p>
                    Mantén la conexión con tus asistentes después del evento, generando lealtad y mejorando la
                    recurrencia. Consigue que se sientan escuchados.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-gray-800 py-20">
            <div className="container mx-auto px-4 text-center">
              <h2 className="text-2xl font-bold mb-8">Mejoremos juntos, agendemos una llamada.</h2>
              <p className="mb-8">
                Te aconsejo hacerlo ahora, porque con unos pocos clientes más cambiaremos el sistema de trabajo
                personalizado y lo mantendremos solo con los clientes que tengamos hasta la fecha -somos un equipo en
                crecimiento y nos gusta hacer las cosas bien-.
              </p>
              <Button className="bg-purple-600 hover:bg-purple-700 text-lg px-8 py-4" onClick={redirectToSales}>
                <PhoneCall className="w-5 h-5 mr-2" />
                Quiero una demo
              </Button>
              <p className="font-bold italic mt-8">Equipo de BoothBits</p>
            </div>
          </section>

          <section id="faqs" className=" py-20">
            <div className="container mx-auto px-4">
              <h3 className="text-3xl font-bold mb-8 text-center text-white">Preguntas frecuentes</h3>
              <div className="max-w-3xl mx-auto space-y-4">
                {faqs.map((faq, index) => (
                  <div key={index} className="border border-gray-700 rounded-lg">
                    <button
                      className={`flex justify-between items-center w-full p-4 text-left bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600 transition duration-300 ease-in-out ${
                        openFaq === index ? "rounded-t-lg" : "rounded-lg"
                      }`}
                      onClick={() => toggleFaq(index)}
                    >
                      <span className="font-bold text-purple-400">{faq.question}</span>
                      {openFaq === index ? (
                        <ChevronUp className="h-5 w-5 text-gray-400" />
                      ) : (
                        <ChevronDown className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                    {openFaq === index && (
                      <div className="p-4 bg-gray-700 rounded-b-lg">
                        <p className="text-gray-300">{faq.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="mt-8 text-center mx-auto">
                <p className="">¿Se han resuelto tus dudas? ¿Te queda alguna?</p>
                <p className="">La solución a toda la incertidumbre en tu evento aquí:</p>
              </div>
              <div className="mt-8 text-center">
                <Button className="bg-purple-600 hover:bg-purple-700 text-lg px-8 py-4" onClick={redirectToSales}>
                  Quiero una demo
                </Button>
              </div>
            </div>
          </section>

          <section className="bg-gray-800 py-20">
            <div className="flex justify-around max-w-3xl mx-auto space-y-4">
              <img className="h-[150px]" src="/laptop-dashboard.svg" alt="Laptop Dashboard" />
              <img className="h-[150px]" src="/cellphone-form.svg" alt="Cellphone form" />
            </div>
          </section>
        </main>
        <footer className="bg-gray-900 py-12">
          <div className="container mx-auto px-4">
            <div className="flex justify-between gap-8">
              <img className="h-8" src="/boothbits-logo.svg" alt="BoothBits logo" />
              <div>
                <ul className="space-y-2">
                  <li>
                    <Link to="/tcs" className="text-gray-400 hover:text-white">
                      Términos y condiciones
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy" className="text-gray-400 hover:text-white">
                      Política de privacidad
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
              <p>&copy; 2024 BoothBits. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}
