import mixpanel from 'mixpanel-browser'
import {isTestEnvironment} from '../environment.ts'

const projectToken = isTestEnvironment ?
    "<FILL>" : // Testing
    "<FILL>"   // Production

mixpanel.init(projectToken, {
    api_host: "https://mixpanel-tracking-proxy-7tducinnmq-uc.a.run.app",
})
