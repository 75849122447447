import { useEffect } from "react"
import { Link } from "react-router-dom"

export function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0) 
  }, [])
  return (
    <div className="min-h-screen bg-gray-900 text-white py-20">
      <header className="container mx-auto px-4 py-6 flex justify-between items-center">
        <div className="text-2xl font-bold">
          <Link to="/" className="text-gray-400 hover:text-white">
            <img className="h-12" src="/boothbits-logo.svg" alt="BoothBits logo" />
          </Link>
        </div>
      </header>
      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold mb-8">Términos y Condiciones</h1>
        <span>I. INFORMACIÓN GENERAL</span>
        <p className="mb-4">
          En cumplimiento con el deber de información dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la
          Información y el Comercio Electrónico (LSSI-CE) de 11 de julio, se facilitan a continuación los siguientes
          datos de información general de este sitio web: La titularidad de este sitio web, Boothbits.com, (en adelante,
          Sitio Web) la ostenta: Jorge Alemán, con NIF: 0, y cuyos datos de contacto son: Dirección: C/ Avenida Alto de
          Las Atalayas Teléfono de contacto: 689572319 Email de contacto: infoboothbits@gmail.com
        </p>
        <span>II. TÉRMINOS Y CONDICIONES GENERALES DE USO</span>
        <p className="mb-4">
          El objeto de las condiciones: El Sitio Web El objeto de las presentes Condiciones Generales de Uso (en
          adelante, Condiciones) es regular el acceso y la utilización del Sitio Web. A los efectos de las presentes
          Condiciones se entenderá como Sitio Web: la apariencia externa de los interfaces de pantalla, tanto de forma
          estática como de forma dinámica, es decir, el árbol de navegación; y todos los elementos integrados tanto en
          los interfaces de pantalla como en el árbol de navegación (en adelante, Contenidos) y todos aquellos servicios
          o recursos en línea que en su caso ofrezca a los Usuarios (en adelante, Servicios). BoothBits se reserva la
          facultad de modificar, en cualquier momento, y sin aviso previo, la presentación y configuración del Sitio Web
          y de los Contenidos y Servicios que en él pudieran estar incorporados. El Usuario reconoce y acepta que en
          cualquier momento BoothBits pueda interrumpir, desactivar y/o cancelar cualquiera de estos elementos que se
          integran en el Sitio Web o el acceso a los mismos. Aparte del coste de conexión a través de la red de
          telecomunicaciones suministrada por el proveedor de acceso, y que hubiere contratado el Usuario, algunos de
          los Contenidos o Servicios ofrecidos por BoothBits o, en su caso, terceros a través del Sitio Web pueden
          encontrarse sujetos a la contratación previa del Contenido o Servicio, en cuyo caso se especificará de forma
          clara y/o se pondrá a disposición del Usuario las correspondientes Condiciones Generales o Particulares por
          las que esto se rija. La utilización de alguno de los Contenidos o Servicios del Sitio Web podrá hacerse
          mediante la suscripción o registro previo del Usuario. El Usuario El acceso, la navegación y uso del Sitio
          Web, así como por los espacios habilitados para interactuar entre los Usuarios, y el Usuario y BoothBits, como
          los comentarios y/o espacios de blogging, confiere la condición de Usuario, por lo que se aceptan, desde que
          se inicia la navegación por el Sitio Web, todas las Condiciones aquí establecidas, así como sus ulteriores
          modificaciones, sin perjuicio de la aplicación de la correspondiente normativa legal de obligado cumplimiento
          según el caso. Dada la relevancia de lo anterior, se recomienda al Usuario leerlas cada vez que visite el
          Sitio Web. El Sitio Web de BoothBits proporciona gran diversidad de información, servicios y datos. El Usuario
          asume su responsabilidad para realizar un uso correcto del Sitio Web. Esta responsabilidad se extenderá a: Un
          uso de la información, Contenidos y/o Servicios y datos ofrecidos por BoothBits sin que sea contrario a lo
          dispuesto por las presentes Condiciones, la Ley, la moral o el orden público, o que de cualquier otro modo
          puedan suponer lesión de los derechos de terceros o del mismo funcionamiento del Sitio Web. La veracidad y
          licitud de las informaciones aportadas por el Usuario en los formularios extendidos por BoothBits para el
          acceso a ciertos Contenidos o Servicios ofrecidos por el Sitio Web. En todo caso, el Usuario notificará de
          forma inmediata a BoothBits acerca de cualquier hecho que permita el uso indebido de la información registrada
          en dichos formularios, tales como, pero no solo, el robo, extravío, o el acceso no autorizado a
          identificadores y/o contraseñas, con el fin de proceder a su inmediata cancelación. BoothBits se reserva el
          derecho de retirar todos aquellos comentarios y aportaciones que vulneren la ley, el respeto a la dignidad de
          la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, spamming, que atenten contra la
          juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultaran adecuados para su
          publicación. En cualquier caso, BoothBits no será responsable de las opiniones vertidas por los Usuarios a
          través de comentarios u otras herramientas de blogging o de participación que pueda haber. El mero acceso a
          este Sitio Web no supone entablar ningún tipo de relación de carácter comercial entre BoothBits y el Usuario.
          Siempre en el respeto de la legislación vigente, este Sitio Web de BoothBits se dirige a todas las personas,
          sin importar su edad, que puedan acceder y/o navegar por las páginas del Sitio Web.
        </p>
        <span>III. ACCESO Y NAVEGACIÓN EN EL SITIO WEB: EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</span>
        <p className="mb-4">
          BoothBits no garantiza la continuidad, disponibilidad y utilidad del Sitio Web, ni de los Contenidos o
          Servicios. BoothBits hará todo lo posible por el buen funcionamiento del Sitio Web, sin embargo, no se
          responsabiliza ni garantiza que el acceso a este Sitio Web no vaya a ser ininterrumpido o que esté libre de
          error. Tampoco se responsabiliza o garantiza que el contenido o software al que pueda accederse a través de
          este Sitio Web, esté libre de error o cause un daño al sistema informático (software y hardware) del Usuario.
          En ningún caso BoothBits será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan
          por el acceso, navegación y el uso del Sitio Web, incluyéndose, pero no limitándose, a los ocasionados a los
          sistemas informáticos o los provocados por la introducción de virus. BoothBits tampoco se hace responsable de
          los daños que pudiesen ocasionarse a los usuarios por un uso inadecuado de este Sitio Web. En particular, no
          se hace responsable en modo alguno de las caídas, interrupciones, falta o defecto de las telecomunicaciones
          que pudieran ocurrir.
        </p>
        <span>IV. POLÍTICA DE ENLACES</span>
        <p className="mb-4">
          Se informa que el Sitio Web de BoothBits pone o puede poner a disposición de los Usuarios medios de enlace
          (como, entre otros, links, banners, botones), directorios y motores de búsqueda que permiten a los Usuarios
          acceder a sitios web pertenecientes y/o gestionados por terceros. La instalación de estos enlaces, directorios
          y motores de búsqueda en el Sitio Web tiene por objeto facilitar a los Usuarios la búsqueda de y acceso a la
          información disponible en Internet, sin que pueda considerarse una sugerencia, recomendación o invitación para
          la visita de los mismos. BoothBits no ofrece ni comercializa por sí ni por medio de terceros los productos y/o
          servicios disponibles en dichos sitios enlazados. Asimismo, tampoco garantizará la disponibilidad técnica,
          exactitud, veracidad, validez o legalidad de sitios ajenos a su propiedad a los que se pueda acceder por medio
          de los enlaces. BoothBits en ningún caso revisará o controlará el contenido de otros sitios web, así como
          tampoco aprueba, examina ni hace propios los productos y servicios, contenidos, archivos y cualquier otro
          material existente en los referidos sitios enlazados. BoothBits no asume ninguna responsabilidad por los daños
          y perjuicios que pudieran producirse por el acceso, uso, calidad o licitud de los contenidos, comunicaciones,
          opiniones, productos y servicios de los sitios web no gestionados por BoothBits y que sean enlazados en este
          Sitio Web. El Usuario o tercero que realice un hipervínculo desde una página web de otro, distinto, sitio web
          al Sitio Web de BoothBits deberá saber que: No se permite la reproducción —total o parcialmente— de ninguno de
          los Contenidos y/o Servicios del Sitio Web sin autorización expresa de BoothBits. No se permite tampoco
          ninguna manifestación falsa, inexacta o incorrecta sobre el Sitio Web de BoothBits, ni sobre los Contenidos
          y/o Servicios del mismo. A excepción del hipervínculo, el sitio web en el que se establezca dicho hiperenlace
          no contendrá ningún elemento, de este Sitio Web, protegido como propiedad intelectual por el ordenamiento
          jurídico español, salvo autorización expresa de BoothBits. El establecimiento del hipervínculo no implicará la
          existencia de relaciones entre BoothBits y el titular del sitio web desde el cual se realice, ni el
          conocimiento y aceptación de BoothBits de los contenidos, servicios y/o actividades ofrecidas en dicho sitio
          web, y viceversa.
        </p>
        <span>V. PROPIEDAD INTELECTUAL E INDUSTRIAL</span>
        <p className="mb-4">
          BoothBits por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e
          industrial del Sitio Web, así como de los elementos contenidos en el mismo (a título enunciativo y no
          exhaustivo, imágenes, sonido, audio, vídeo, software o textos, marcas o logotipos, combinaciones de colores,
          estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento,
          acceso y uso, etc.). Serán, por consiguiente, obras protegidas como propiedad intelectual por el ordenamiento
          jurídico español, siéndoles aplicables tanto la normativa española y comunitaria en este campo, como los
          tratados internacionales relativos a la materia y suscritos por España. Todos los derechos reservados. En
          virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la
          distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte
          de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio
          técnico, sin la autorización de BoothBits. El Usuario se compromete a respetar los derechos de propiedad
          intelectual e industrial de BoothBits. Podrá visualizar los elementos del Sitio Web o incluso imprimirlos,
          copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando
          sea, exclusivamente, para su uso personal. El Usuario, sin embargo, no podrá suprimir, alterar, o manipular
          cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en el Sitio Web. En caso de
          que el Usuario o tercero considere que cualquiera de los Contenidos del Sitio Web suponga una violación de los
          derechos de protección de la propiedad intelectual, deberá comunicarlo inmediatamente a BoothBits a través de
          los datos de contacto del apartado de INFORMACIÓN GENERAL de este Aviso Legal y Condiciones Generales de Uso.
        </p>
        <span>VI. ACCIONES LEGALES, LEGISLACIÓN APLICABLE Y JURISDICCIÓN</span>
        <p className="mb-4">
          BoothBits se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la
          utilización indebida del Sitio Web y Contenidos, o por el incumplimiento de las presentes Condiciones. La
          relación entre el Usuario y BoothBits se regirá por la normativa vigente y de aplicación en el territorio
          español. De surgir cualquier controversia en relación con la interpretación y/o a la aplicación de estas
          Condiciones las partes someterán sus conflictos a la jurisdicción ordinaria sometiéndose a los jueces y
          tribunales que correspondan conforme a derecho. Este documento de Aviso Legal y Condiciones Generales de uso
          del sitio web ha sido creado mediante el generador de plantilla de aviso legal y condiciones de uso online el
          día 05/10/2024.
        </p>
      </div>
    </div>
  )
}
